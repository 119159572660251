import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {applyPolyfills, defineCustomElements} from 'ionicons/dist/loader';

// This enables ionicons to show by telling the Ionicons script to look for the svg directory within assets/ionicons/
// https://github.com/ionic-team/ionicons/issues/769
applyPolyfills().then(() => {
  defineCustomElements(window, {
    resourcesUrl: 'assets/ionicons/'
  });
});

platformBrowserDynamic().bootstrapModule(AppModule);
