import {fixedEnvironment} from '../../environments/environment.fixed';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {EnvironmentService} from '../services/environment/environment.service';
import {versions} from '../../environments/settings';
import {EnvironmentSettings} from '../../environments/environment-settings';

export class EnvironmentConfig {
  version = 'noversion';
  backendUrl = '';
  versionCheckUrl = '';
  owoActive = false;
  owoFrontendUrl = 'http://localhost:4201';
  maxReceiveContacts: 5;
  configuration = 'local';
  revision = 'norevision';
  hash = 'nohash';
  sentryUrl: string;
  production = false;
  settings: EnvironmentSettings = {};
  assetsFolder: string = fixedEnvironment.assetsFolder;
  apiVersion: string = fixedEnvironment.apiVersion;
}

/* eslint-disable @typescript-eslint/no-var-requires */
export function loadEnvironmentFromFile(http: HttpClient, environmentService: EnvironmentService): () => Promise<EnvironmentConfig> {
  const location = 'assets/environment-config.json';
  return () => firstValueFrom(http.get(`${location}`))
    .then(loadedConfig => {
      const configToSet = !loadedConfig ? new EnvironmentConfig() : {
        ...fixedEnvironment,
        ...loadedConfig,
        ...versions
      } as EnvironmentConfig;
      environmentService.setEnv(configToSet);
      return environmentService.getEnv();
    });
}
