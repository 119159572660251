import {Injectable} from '@angular/core';
import {LanguageSpec} from 'app/domain/translator/language';
import {IdentityTypes} from '../../domain/identity/identitytypes';
import {IMenuItem, MenuAlignment} from '../../layouts/header/menu-item.interface';
import {PermissionName} from '../../domain/userdata';
import {EnvironmentConfig} from 'app/configs/environment.config';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly app: any;
  private readonly internalMenuItems: IMenuItem[];
  private readonly externalMenuItems: IMenuItem[];

  constructor(
    protected readonly _ENV_CONFIG: EnvironmentConfig,
  ) {

    // Global Settings
    // -----------------------------------
    this.app = {
      backend: {
        baseUrl: this._ENV_CONFIG.backendUrl,
        apiVersion: this._ENV_CONFIG.apiVersion
      },
      language: {
        default: new LanguageSpec('nl', 'Nederlands'),
        options: [
          new LanguageSpec('en', 'English'),
          new LanguageSpec('nl', 'Nederlands')
        ]
      },
      emails: {
        p4p: 'info@power4people.nl'
      },
      header: {
        menulink: 'menu-link-slide'
      },
      footerHidden: false,
      viewAnimation: 'ng-fadeInLeftShort',
      internalMenu: [
        {
          align: MenuAlignment.Left,
          nameCode: 'actions',
          urlPath: '/action/list',
          altUrls: ['/action/detail*'],
          actions: ['action.own',
            'action.all',
            'action.client',
            'action.planner',
            'action.vraagbaak',
            'action.nakijker',
            'action.poort'
          ],
          permission: PermissionName.Read
        },
        {
          align: MenuAlignment.Left,
          nameCode: IdentityTypes.Client,
          urlPath: '/identity/list/client',
          altUrls: ['/identity/client/*'],
          actions: ['account.client', 'account.all'],
          permission: PermissionName.Read
        },
        {
          align: MenuAlignment.Left,
          nameCode: 'owners',
          urlPath: '/identity/list',
          altUrls: ['/identity/doorverwijzer/detail/*', '/identity/werkgever/detail/*'],
          actions: [
            'account.all',
            'account.doorverwijzer',
            'account.werkgever',
            'account.client',
          ],
          permission: PermissionName.Read,
          submenu: [
            {
              nameCode: 'owners.' + IdentityTypes.Doorverwijzer,
              urlPath: '/identity/list/doorverwijzer',
              actions: ['account.doorverwijzer', 'account.all'],
              permission: PermissionName.Read
            },
            {
              nameCode: 'owners.' + IdentityTypes.Werkgever,
              urlPath: '/identity/list/werkgever',
              actions: ['account.werkgever', 'account.all'],
              permission: PermissionName.Read
            },
            {
              nameCode: 'owners.' + IdentityTypes.Client,
              urlPath: '/identity/list/client/opdrachtgever',
              actions: ['account.client', 'account.all'],
              permission: PermissionName.Read
            }
          ]
        },
        {
          align: MenuAlignment.Left,
          nameCode: 'colleagues',
          urlPath: '/account/list/internal',
          altUrls: ['/account/new/internal', '/profile/internal/*'],
          actions: [
            'account.all',
            'account.planner',
            'account.coach',
            'account.financial',
            'account.trajectassistent',
            'account.freelancer',
            'account.vraagbaak',
            'account.nakijker'
          ],
          permission: PermissionName.Read
        },
        {
          align: MenuAlignment.Left,
          nameCode: 'toolboxes',
          urlPath: '/toolboxes',
          altUrls: ['/toolboxes/*'],
          actions: ['account.own'],
          permission: PermissionName.Read
        },
        {
          align: MenuAlignment.Right,
          nameCode: 'externalUsers',
          actions: [
            'account.client',
            'account.werkgever',
            'account.doorverwijzer'
          ],
          permission: PermissionName.All,
          urlPath: '/account/list/external',
          altUrls: ['/account/new/external', '/profile/external/*']
        },
        {
          align: MenuAlignment.Right,
          nameCode: 'openQuotations',
          actions: ['service.all'],
          permission: PermissionName.Read,
          urlPath: '/service/list',
          altUrls: ['/service/list/*']
        },
        {
          align: MenuAlignment.Right,
          nameCode: 'shop',
          altUrls: ['/shop/*'],
          urlPath: '/shop',
          actions: ['category', 'globalTemplates', 'toolboxes'],
          identityTypes: [IdentityTypes.Admin],
          permission: PermissionName.Create,
          submenu: [
            {
              nameCode: 'shop.products',
              urlPath: '/shop/category/list',
              actions: ['category'],
              permission: PermissionName.Create
            },
            {
              nameCode: 'shop.documents',
              urlPath: '/shop/documents',
              actions: ['globalTemplates'],
              permission: PermissionName.Create
            },
            {
              nameCode: 'shop.toolboxes',
              urlPath: '/shop/toolbox/list',
              altUrls: ['/shop/toolbox/*'],
              actions: ['toolboxes'],
              permission: PermissionName.Create
            },
            {
              nameCode: 'shop.emailTemplates',
              urlPath: '/shop/emailTemplates/list',
              altUrls: ['/shop/emailTemplates/*'],
              actions: ['emailTemplates'],
              permission: PermissionName.Create
            }
          ]
        },
        SettingsService.userMenuItem(true)
      ],
      externalMenu: [
        {
          align: MenuAlignment.Left,
          nameCode: IdentityTypes.Client,
          urlPath: '/identity/list/client',
          altUrls: ['/identity/client/*'],
          actions: ['account.own'],
          identityTypes: [IdentityTypes.Werkgever, IdentityTypes.Doorverwijzer],
          permission: PermissionName.Read
        },
        {
          align: MenuAlignment.Left,
          nameCode: 'dossier',
          urlPath: '/identity/list/client',
          altUrls: ['/identity/client/*'],
          actions: ['account.own'],
          identityTypes: [IdentityTypes.Client],
          permission: PermissionName.Read
        },
        {
          align: MenuAlignment.Left,
          nameCode: IdentityTypes.Werkgever,
          urlPath: '/identity/list/werkgever',
          altUrls: ['/identity/werkgever/*'],
          actions: ['account.own'],
          identityTypes: [IdentityTypes.Doorverwijzer],
          permission: PermissionName.Read
        },
        {
          align: MenuAlignment.Left,
          nameCode: 'openQuotations',
          urlPath: '/service/list',
          altUrls: ['/service/list/*'],
          actions: ['service.own'],
          permission: PermissionName.Read
        },
        {
          align: MenuAlignment.Right,
          nameCode: 'newQuotation',
          urlPath: '/shop/to-shop',
          showAsButton: true
        },
        SettingsService.userMenuItem(false)
      ],
      themesClass: 'theme-',
      themes: [
        {id: 1, name: 'Theme 1'},
        {id: 2, name: 'Theme 2'},
        {id: 3, name: 'Theme 3'},
        {id: 4, name: 'Theme 4'},
        {id: 5, name: 'Theme 5'},
        {id: 6, name: 'Theme 6'},
        {id: 7, name: 'Theme 7'},
        {id: 8, name: 'Theme 8'}
      ],
      version: {
        full: this._ENV_CONFIG.configuration + ' (' +
            this._ENV_CONFIG.version + ' - ' +
            this._ENV_CONFIG.hash + ' - ' +
            this._ENV_CONFIG.revision + ')',
        configuration: this._ENV_CONFIG.configuration,
        version: this._ENV_CONFIG.version,
        hash: this._ENV_CONFIG.hash,
        revision: this._ENV_CONFIG.revision,
        filterVersion: 'v1',
        sortVersion: 'v1'
      }
    };

    this.internalMenuItems = SettingsService.flattenMenuItems(this.app.internalMenu);
    this.externalMenuItems = SettingsService.flattenMenuItems(this.app.externalMenu);
  }

  static getActiveMenuItems(menuItems: IMenuItem[], url: string): string[] {
    let activeMenuItems: IMenuItem[] = menuItems.filter((item) => item.urlPath === url);
    const subMenuHeaders: IMenuItem[] = menuItems.filter((item) => url.startsWith(item.urlPath) && !!item.submenu);

    subMenuHeaders.forEach((sm) => {
      const subMenuItems: IMenuItem[] = sm.submenu.filter((subMenuItem) => subMenuItem.urlPath === url);
      if (subMenuItems.length > 0) {
        activeMenuItems.push(sm);
        activeMenuItems = activeMenuItems.concat(subMenuItems);
      }
    });

    // If any of the alternate URLs on an item or its subitems match the current URL, set that item to active.
    menuItems.forEach((am) => {
      if (!!am.altUrls && am.altUrls.some((altUrl) => this.matchWithWildCard(url, altUrl))) {
        activeMenuItems.push(am);
      }

      if (am.submenu) {
        am.submenu.filter((asm) => !!asm.altUrls).forEach((asm) => {
          if (asm.altUrls.some((altUrl) => this.matchWithWildCard(url, altUrl))) {
            activeMenuItems.push(am, asm);
          }
        });
      }
    });

    return activeMenuItems.map((mi) => mi.nameCode);
  }

  /**
   * Match urls, taking into account the possibility for a wildcard.
   * Current implementation only works for a single wildcard (*), which accepts all text after it.
   */
  private static matchWithWildCard(url: string, itemUrl: string): boolean {
    if (itemUrl.indexOf('*') > -1) {
      return url.startsWith(itemUrl.split('*')[0]);
    } else {
      return url === itemUrl;
    }
  }

  /**
   * Retrieve a setting by its first name
   *
   * @param name
   * @param array
   * @returns
   */
  getSetting(name: string, array: any = this.app): any {
    const nameParts = name.split('.');

    if (nameParts.length > 1) {
      return this.getSetting(nameParts.slice(1).join('.'), array[nameParts[0]]);
    } else {
      return nameParts[0] ? array[nameParts[0]] : array;
    }
  }

  /**
   * Returns the full url of a backend service
   */
  backendUrl(path: string): string {
    return encodeURI(`${this.getSetting('backend.baseUrl')}${this.getSetting('backend.apiVersion')}${path}`);
  }

  /**
   * Update a setting.
   *
   * @param name
   * @param value
   * @returns
   */
  setSetting(name: string, value: any): any {
    if (typeof this.app[name] !== 'undefined') {
      this.app[name] = value;
      return value;
    }
  }

  /**
   * Toggle a setting
   *
   * @param name
   * @returns
   */
  toggleSetting(name: string): boolean {
    return this.setSetting(name, !this.getSetting(name));
  }

  /**
   * Flatten an array of MenuItems and nested sub-MenuItems into one array.
   */
  private static flattenMenuItems(menuItems: IMenuItem[]): IMenuItem[] {
    return menuItems.reduce((acc: IMenuItem[], menuItem) => {
          // for every menu item, concat the item with its sub-menu items (if they exist) and append to the accumulator
          if (menuItem.submenu !== undefined) {
            return acc.concat([menuItem].concat(menuItem.submenu));
            // or just append the items itself if there are no sub-menu item
          } else {
            return acc.concat([menuItem]);
          }
        },
        // start with an empty array
        []
    );
  }

  private static userMenuItem(internalUser: boolean): IMenuItem {
    const subMenu: IMenuItem[] = [
      {
        nameCode: 'user.profile',
        actions: ['account.own'],
        permission: PermissionName.Update,
        urlPath: '/profile',
      },
      {
        nameCode: 'user.newPassword',
        actions: ['account.own'],
        permission: PermissionName.Update,
        urlPath: '/account/newPassword'
      }
    ];

    const archiveMenuItems = [
      {
        nameCode: 'user.archiveOpened',
        actions: ['archived.view'],
        identityTypes: [
          IdentityTypes.Admin,
          IdentityTypes.Poort,
          IdentityTypes.Financial
        ],
        permission: PermissionName.Update,
      }, {
        nameCode: 'user.archiveClosed',
        actions: ['archived.view'],
        identityTypes: [
          IdentityTypes.Admin,
          IdentityTypes.Poort,
          IdentityTypes.Financial
        ],
        permission: PermissionName.Update,
      }];

    const viewQr = {
      nameCode: 'user.totpQR',
      actions: ['account.own'],
      permission: PermissionName.Read,
      urlPath: '/account/totp/viewQR'
    };

    const logout = {
      nameCode: 'user.logout',
      urlPath: '/logout'
    };

    return {
      align: MenuAlignment.Right,
      nameCode: 'user',
      submenu: internalUser ?
          [...archiveMenuItems, ...subMenu, viewQr, logout] :
          [...subMenu, logout]
    };
  }

  /**
   * Find an return the menu item for the current route.
   * Parses either the internal or external menu items based on the user type.
   *
   * @param route: the route to find
   * @param getInternalMenuItems: which menu item array to parse
   */
  getMenuItemByRoute(route: string, getInternalMenuItems: boolean): IMenuItem {
    // use the internal or external menu based on user type
    const menuItems: IMenuItem[] = getInternalMenuItems ? this.internalMenuItems : this.externalMenuItems;

    // find the MenuItem that matches the route, either directly or via an altUrl
    return menuItems.find((mi) =>
        mi.urlPath === route ||
        mi.altUrls !== undefined && mi.altUrls.some((altUrl) => SettingsService.matchWithWildCard(route, altUrl))
    );
  }
}
