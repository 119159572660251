import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {SettingsService} from 'app/services/settings/settings.service';
import {LoginService} from 'app/services/login/login.service';

import {filter, map, takeUntil} from 'rxjs/operators';
import {IMenuItem, MenuAlignment} from './menu-item.interface';
import {firstValueFrom, Subject} from 'rxjs';
import {LoginStateService} from 'app/services/login/loginstate.service';
import _ from 'lodash';
import {OwoAuthRequest, OwoService} from '../../services/owo/owo.service';
import {UserService} from '../../services/userlist/user.service';
import {UserWithPermissions} from '../../domain/userdata';
import {EnvironmentConfig} from 'app/configs/environment.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public leftMenuItems: Array<IMenuItem> = [];
  public rightMenuItems: Array<IMenuItem> = [];
  public activeMenuItems: string[];
  public isCollapsed: boolean = true;
  public assetsFolder: string;

  private _currentUser: UserWithPermissions;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    protected readonly _ENV_CONFIG: EnvironmentConfig,
    private settingsService: SettingsService,
    private loginService: LoginService,
    private owoService: OwoService,
    private _userService: UserService,
    private router: Router,
    private loginStateService: LoginStateService
  ) {
    this.assetsFolder = this._ENV_CONFIG.assetsFolder;
  }

  ngOnInit() {
    this.loginService.getUser$().pipe(
      filter((userData) => userData !== null),
    ).subscribe((userData) => {
      this._currentUser = userData;
      let menuItems: Array<IMenuItem>;
      if (userData.user.userIdentity) {
        const menuCode = userData.user.isInternalUser() ?
          'internalMenu' : 'externalMenu';
        menuItems = this.settingsService.getSetting(menuCode);
      } else {
        menuItems = this.settingsService.getSetting('externalMenu');
      }

      const userIdentityType = userData.user.userIdentity.idType;
      // Empty identityTypes[] means that we do not filter on IdentityType.
      const filteredMenuItems = menuItems.filter((mi) => !mi.identityTypes || mi.identityTypes.includes(userIdentityType));

      this.leftMenuItems = _.cloneDeep(filteredMenuItems.filter((mi) => mi.align === MenuAlignment.Left));
      this.rightMenuItems = _.cloneDeep(filteredMenuItems.filter((mi) => mi.align === MenuAlignment.Right));

      this._setActiveItems(this.router.url);
    });

    this.router.events.pipe(
      takeUntil(this.onDestroy$),
      filter((event) => event instanceof NavigationEnd))
      .subscribe(
        (data: NavigationEnd) => this._setActiveItems(data.urlAfterRedirects)
      );

    this.rightMenuItems.forEach(m =>
      m.submenu = m.submenu?.filter(s =>
        s.nameCode !== this._getNameForInactiveArchiveMenuItem())); // remove archived item based on the toggle

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Determine and set the active menu items.
   *
   * @param url
   */
  private _setActiveItems(url: string): void {
    const menuItems = this.leftMenuItems.concat(this.rightMenuItems);

    this.activeMenuItems = SettingsService.getActiveMenuItems(menuItems, url);
  }

  public menuItemClicked(item: IMenuItem): void {
    if (item.fn) {
      item.fn();
    } else {
      if (item.nameCode === 'user.archiveOpened' || item.nameCode === 'user.archiveClosed') {
        this._toggleArchived(item);
      } else if (item.urlPath === '/logout') {
        this._logout();
      } else if (item.nameCode === 'toolboxes') {
        this._openOwo();
      } else {
        this.toPage(item.urlPath);
      }
    }
  }

  private _toggleArchived(item: IMenuItem): void {
    if (item.nameCode === 'user.archiveOpened' && this.loginStateService.showArchived$.value) {
      this.loginStateService.removeShowArchivedFromLocalStorage();
    } else {
      this.loginStateService.setShowArchivedInLocalStorage();
    }
    window.location.reload();
  }

  // filters out the menu item for the current menu item
  private _getNameForInactiveArchiveMenuItem(): string {
    const showArchived = this.loginStateService.showArchived$.value;
    return showArchived ? 'user.archiveClosed' : 'user.archiveOpened';
  }

  /**
   * Logout
   */
  private _logout(): void {
    this.loginService.logout();
  }

  /**
   * Get token with identity id and open new owo window
   */
  private _getToken(): void {
    void firstValueFrom(this.owoService.getToken(
      new OwoAuthRequest(Number(this._currentUser.user.userIdentity.id.id), Number(this._currentUser.user.userIdentity.id.id)))
      .pipe(takeUntil(this.onDestroy$), map((token) =>
        // eslint-disable-next-line max-len
        window.open(this._ENV_CONFIG.owoFrontendUrl + `/pelogin?token=${token.token}&userId=${token.userId}&effectiveUserId=${token.effectiveUserId}`, '_blank')
      )));
  }

  /**
   * Register and open account in OWO
   */
  private _openOwo(): void {
    if (!this._currentUser.user.userIdentity.owoId) {
      firstValueFrom(this.owoService.createOwoAccount(
        this._currentUser.user.userIdentity,
        this._currentUser.user.userIdentity.idType
      )).then((owoId) => {
          this._currentUser.user.userIdentity.owoId = owoId;
          this._getToken();
        }
      );
    } else {
      this._getToken();
    }
  }

  private toPage(link: string): void {
    this.router.navigate([link]);
    this.isCollapsed = true;
  }
}
