import {TranslateLoader} from '@ngx-translate/core';
import {forkJoin, Observable, of, shareReplay} from 'rxjs';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {EnvironmentConfig} from "../../configs/environment.config";
import {EnvironmentService} from "../environment/environment.service";
import {catchError, map, switchMap} from "rxjs/operators";
import {deepmerge} from "deepmerge-ts";
import {ITranslationResource} from "ngx-translate-multi-http-loader";

/**
 * Loads translations by merging them all during compile/build time. All translations end up in the js bundle.
 */
export class BackendTranslationLoader extends TranslateLoader {
  constructor(
    private _httpBackend: HttpBackend,
    private _envService: EnvironmentService,
  ) {
    super();
  }

  /**
   * Gets the translations from the server
   */
  public getTranslation(language: string): Observable<object> {
    return this._envService.getEnv$().pipe(
      map(BackendTranslationLoader.envToPrefixes),
      switchMap((resourcesPrefix) => this._getTranslation(language, resourcesPrefix)),
      shareReplay(1)
    );
  }

  private static envToPrefixes(env: EnvironmentConfig): ITranslationResource[] {
    return [
      {prefix: `${env.assetsFolder}/i18n/`, suffix: '.json'},
      {prefix: `${env.backendUrl}${env.apiVersion}/publicAssets/`, suffix: `.json&time=${new Date().valueOf()}`}
    ];
  }

  private _getTranslation(lang: string, resourcesPrefix: ITranslationResource[]): Observable<object> {
    const requests: Observable<object>[] = resourcesPrefix.map((resource) => {
      const path = resource.prefix ? `${resource.prefix}${lang}${resource.suffix || '.json'}` : `${resource}${lang}.json`;
      return new HttpClient(this._httpBackend).get(path).pipe(catchError((res) => {
        if (!resource.optional) {
          console.group();
          console.error('Something went wrong for the following translation file:', path);
          console.error(res);
          console.groupEnd();
        }
        return of({});
      }));
    });
    return forkJoin(requests).pipe(map((response) => deepmerge(...response) as object));
  }
}
