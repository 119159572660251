import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IMenuItem} from '../../layouts/header/menu-item.interface';
import {filter} from 'rxjs/operators';
import {LoginStateService} from '../../services/login/loginstate.service';
import {Identity} from '../../domain/identity/identitydata';
import {IdentityTypes} from '../../domain/identity/identitytypes';
import {EnvironmentConfig} from 'app/configs/environment.config';

@Component({
  selector: 'navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss']
})
export class NavbarMenuComponent {
  @Input() public menuItems: Array<IMenuItem> = [];
  @Input() public activeMenuItems: string[];

  @Output() clicked: EventEmitter<IMenuItem> = new EventEmitter<IMenuItem>();

  public isSubmenu: boolean = false;
  public userIdentity: Identity;

  public assetsFolder: string;
  public isAdmin: boolean = false;

  constructor(
    protected readonly _ENV_CONFIG: EnvironmentConfig,
    private loginStateService: LoginStateService
  ) {
    loginStateService.currentUser$.pipe(filter((u) => !!u)).subscribe((u) => {
      this.userIdentity = u.user.userIdentity;
      this.isAdmin = u.user.userIdentity.idType === IdentityTypes.Admin;
    });
    this.assetsFolder = _ENV_CONFIG.assetsFolder;
  }

  itemClicked(item: IMenuItem): void {
    this.clicked.emit(item);
  }

  showArchived(item: IMenuItem): boolean {
    // external users should not see the archived icon, even though they always have showArchived set to true.
    return this.userIdentity.isInternal() && item.nameCode === 'user' && this.loginStateService.showArchived$.value;
  }

  showArchiveOpened(item: IMenuItem): boolean {
    return item.nameCode === 'user.archiveOpened' && this.loginStateService.showArchived$.value;
  }

  showArchiveClosed(item: IMenuItem): boolean {
    return item.nameCode === 'user.archiveClosed' && !this.loginStateService.showArchived$.value;
  }

  showItem(item: IMenuItem): boolean {
    return (item.nameCode === 'toolboxes' && (this._ENV_CONFIG.owoActive || this.isAdmin)) || !(item.nameCode === 'toolboxes');
  }
}
