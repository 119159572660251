import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomSnackbarService} from '../../services/snackbar/snackbar.service';
import {getFileSize} from '../../utils/fileDownload';


export const allowedFileExtensions = new Map([
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'docx'],
  ['application/vnd.openxmlformats-officedocument.presentationml.presentation', 'pptx'],
  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx'],
  ['application/pdf', 'pdf'],
  ['image/jpeg', 'jpg, jpeg'],
  ['image/png', 'png']
  ]);

export const allowedFileExtensionsForInternal = new Map([
  ['.msg', 'msg']
]);

/**
 * Component which lets the user upload a file
 */
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() labelKey: string;
  @Input() acceptedExtensions: string;
  @Input() maxFileSize: number = 50; // in MegaBytes
  @Input() loading: boolean = false;
  @Input() hideInput: boolean = false;
  @Input() fileName: string;
  @Input() disabled: boolean = false;
  @Input() isInternal: boolean = false;

  public valid: boolean = true;

  @Output() sendFile: EventEmitter<File> = new EventEmitter<File>();

  constructor() {}

  ngOnInit() {
    const allowedFileExtensionNames = Array.from(allowedFileExtensions.keys()).join(', ');
    const allowedFileExtensionsForInternalNames = Array.from(allowedFileExtensionsForInternal.keys()).join(', ');
    this.acceptedExtensions = this.isInternal ?
      `${allowedFileExtensionNames}, ${allowedFileExtensionsForInternalNames}` :
      allowedFileExtensionNames;
  }

  onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      this.setFile(event.target.files[0] as File);
    }
  }

  setFile(file: File): void {
    if (getFileSize(file.size) > this.maxFileSize) {
      this.valid = false;
    } else {
      this.valid = true;
      this.fileName = file.name;
      this.sendFile.emit(file);
    }
  }
}
