import {Injectable} from '@angular/core';
import {EnvironmentConfig} from '../../configs/environment.config';
import {BehaviorSubject, distinctUntilChanged, filter, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  private _env: EnvironmentConfig = new EnvironmentConfig();
  private _env$: BehaviorSubject<EnvironmentConfig> = new BehaviorSubject(undefined);

  /**
   * Sets the environment ONLY ONCE.
   * Should only be called by application startup.
   *
   * @param env: The new environment config
   */
  public setEnv(env: EnvironmentConfig): void {
    if (env) {
      this._env = env;
      this._env$.next(env);
    }
  }

  /**
   * Sync getter. Returns the (unsafe) config.
   * Internal use only! Env should be set by application startup, and then provided through EnvironmentConfig,
   * so this probably shouldn't be called in your service/module/whatever.
   */
  public getEnv(): EnvironmentConfig {
    return this._env;
  }

  /**
   * Async getter.
   * Internal use only! Env should be set by application startup, and then provided through EnvironmentConfig,
   * so this probably shouldn't be called in your service/module/whatever.
   * This is needed in some very special cases for providers that are instantiated before the
   * APP_INITIALIZER is run, e.g. the ErrorService.
   */
  public getEnv$(): Observable<EnvironmentConfig> {
    return this._env$.pipe(distinctUntilChanged(), filter(cfg => cfg !== undefined));
  }

}
