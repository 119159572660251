import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
/* eslint-disable max-len */
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';

import {ConfirmModalComponent} from '../components/confirm-modal/confirm-modal.component';
import {ListComponent} from '../components/list/list.component';
import {ColumnTemplateComponent} from '../components/list/column.template';
import {SecureActionDirective} from '../directives/secureAction.directive';
import {BreadcrumbsComponent} from '../components/breadcrumbs/breadcrumbs.component';
import {EmployerPageComponent} from '../pages/quotation-request/quotation-flow/employer-page/employer-page.component';
import {ReferrerPageComponent} from '../pages/quotation-request/quotation-flow/referrer-page/referrer-page.component';
import {DynamicIoModule, DynamicModule} from 'ng-dynamic-component';
import {InputFieldComponent} from '../components/input-field/input-field.component';
import {QuestionMarkComponent} from '../components/question-mark/question-mark.component';
import {NawFormComponent} from '../components/naw-form/naw-form.component';
import {CompanyFormComponent} from '../components/company-form/company-form.component';
import {RadioButtonComponent} from '../components/radio-button/radio-button.component';
import {ClientPageComponent} from '../pages/quotation-request/quotation-flow/client-page/client-page.component';
import {LoginComponent} from '../pages/login/login.component';
import {
  CommunicationInfoComponent
} from '../pages/quotation-request/quotation-flow/communication-info/communication-info.component';
import {CommunicationFormComponent} from '../components/communication-form/communication-form.component';
import {
  IdentitiesFormComponent
} from '../pages/quotation-request/quotation-flow/identities-form/identities-form.component';
import {StrippedListComponent} from '../components/list/stripped-list/stripped-list.component';
import {
  ConfirmationPageComponent
} from '../pages/quotation-request/quotation-flow/confirmation-page/confirmation-page.component';
import {ContactButtonsComponent} from '../components/contact-buttons/contact-buttons.component';
// import {CustomFormsModule} from 'ngx-custom-validators/src/app/custom-forms.module';
import {SummaryPageComponent} from '../pages/quotation-request/quotation-flow/summary-page/summary-page.component';
import {
  NgbDatepickerModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbTimepickerModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {BoxComponent} from '../components/box/box.component';
import {FlowInfoComponent} from '../components/flow-info/flow-info.component';
import {DatePickerComponent} from '../components/date-picker/date-picker.component';
import {InputDatePickerComponent} from '../components/date-picker/input-date-picker.component';
import {DatetimePickerComponent} from '../components/datetime-picker/datetime-picker.component';
import {InputDatetimePickerComponent} from '../components/datetime-picker/input-datetime-picker.component';
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import {TextAreaComponent} from '../components/text-area/text-area.component';
import {IdentityNamePipe} from '../pipes/identity-name.pipe';
import {NavbarMenuComponent} from '../components/navbar-menu/navbar-menu.component';
import {QuotationProductComponent} from '../pages/service/detail/product/quotation-product.component';
import {IdentitiesDropdownComponent} from '../components/identities-dropdown/identities-dropdown.component';
import {RichTextModalComponent} from '../components/rich-text-modal/rich-text-modal.component';
import {IdentitySideMenuComponent} from '../components/identity-side-menu/identity-side-menu.component';
import {RouterModule} from '@angular/router';
import {NavbarMenuSubComponent} from '../components/navbar-menu/navbar-menu-sub.component';
import {ModalDefaultComponent} from '../components/modal-default/modal-default.component';
import {RolePageComponent} from '../components/role-page/role-page.component';
import {ExtraProductFieldsComponent} from '../components/extra-product-fields/extra-product-fields.component';
import {FilesUploadComponent} from '../components/files-upload/files-upload.component';
import {FileUploadComponent} from '../components/file-upload/file-upload.component';
import {ListHeaderComponent} from '../components/list/list-header.component';
import {TryTranslatePipe} from '../pipes/try-translate.pipe';
import {ConfirmUnmergeModalComponent} from '../pages/identity/identity-merge/confirm-unmerge-modal.component';
import {LoginContentComponent} from '../pages/login/login-content.component';
import {
  NawCompanyFormComponent
} from '../pages/quotation-request/quotation-flow/identities-form/naw-company-form/naw-company-form.component';
import {
  IdentitySelectionComponent
} from '../pages/quotation-request/quotation-flow/identities-form/identity-selection/identity-selection.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ChangeEmailModalComponent} from '../pages/identity/detail/change-email-modal.component';
import {RejectQuotationModalComponent} from '../pages/service/quotation-modal/reject-quotation-modal.component';
import {RejectReasonsComponent} from '../components/reject-reasons/reject-reasons.component';
import {DropdownExtraComponent} from '../components/dropdown-extra/dropdown-extra.component';
import {DisableDuringRequestDirective} from '../directives/disableDuringRequest.directive';
import {ApproveOrRejectQuotationComponent} from '../pages/approve_quotation/approve-or-reject-quotation.component';
import {ApproveOrRejectDataFormComponent} from '../components/approval-data/approve-or-reject-data-form.component';
import {ApproveOrRejectDataComponent} from '../components/approval-data/approve-or-reject-data.component';
import {CustomSnackbarService} from '../services/snackbar/snackbar.service';
import {
  SideMenuIdentityComponent
} from '../components/identity-side-menu/side-menu-identity/side-menu-identity.component';
import {SortContentComponent} from '../components/list/sort-content/sort-content.component';
import {ConfirmSendModalComponent} from '../components/confirm-modal/send-quotation/confirm-send-modal.component';
import {QuotationContactSummaryComponent} from '../components/quotation-summary/quotation-contact-summary.component';
import {CancelQuotationModalComponent} from '../pages/service/quotation-modal/cancel-quotation-modal.component';
import {DropdownComponent} from '../components/dropdown/dropdown.component';
import {NawNamePipe} from '../pipes/naw-name.pipe';
import {SpinnerComponent} from '../components/spinner/spinner.component';
import {
  ApproveOrRejectDataModalComponent
} from '../pages/quotation/quotation-modal/approve-or-reject-data-modal.component';
import {CollapsibleComponent} from '../components/collapsible/collapsible.component';
import {ProductSessionComponent} from '../components/product-session/product-session.component';
import {ProductInfoPageComponent} from '../pages/shop/product/detail/info/product-info-page.component';
import {ProductSessionsPageComponent} from '../pages/shop/product/detail/sessions/product-sessions-page.component';
import {DocumentActionRowComponent} from '../components/document-action-row/document-action-row.component';
import {SimpleDocumentActionRowComponent} from '../components/document-action-row/simple-document-action-row.component';
import {SessionListComponent} from '../pages/service/detail/sessions/list/session-list.component';
import {SessionPageComponent} from '../pages/service/detail/sessions/session-page.component';
import {SessionDetailComponent} from '../pages/service/detail/sessions/detail/session-detail.component';
import {StopServiceModalComponent} from '../pages/service/quotation-modal/stop-service-modal.component';
import {MultipleChoiceComponent} from '../components/reactive-forms-inputs/multiple-choice/multiple-choice.component';
import {TextAreaInputComponent} from '../components/reactive-forms-inputs/text-input/text-area-input.component';
import {PauseServiceModalComponent} from '../pages/service/quotation-modal/pause-service-modal.component';
import {SwapServiceModalComponent} from '../pages/service/quotation-modal/swap-service-modal.component';
import {
  CategoryProductDropdownComponent
} from '../components/category-product-dropdown/category-product-dropdown.component';
import {WordBreakEmailPipe} from '../pipes/WordBreakEmailPipe';
import {CurrencyInputFieldComponent} from '../components/input-field/currency-input-field.component';
import {OpenFileComponent} from '../components/open-file/open-file.component';
import {SkipModalComponent} from '../pages/service/quotation-modal/skip-modal.component';
import {SkipSessionsAndWeeksComponent} from '../components/skip-sessions-and-weeks/skip-sessions-and-weeks.component';
import {ShowPasswordEyeIconComponent} from '../components/show-password-eye-icon/show-password-eye-icon.component';
import {ReportBoxComponent} from '../components/report-box/report-box.component';
import {TimelineComponent} from '../components/timeline/timeline.component';
import {ActionTranslatePipe} from '../pipes/action-translate.pipe';
import {PaginationControlsComponent} from '../components/pagination-controls/pagination-controls.component';
import {LocaldateFormatPipe} from '../pipes/localdate-format.pipe';
import {LocaldatetimeFormatPipe} from 'app/pipes/localdatetime-format.pipe';
import {CrumbPageComponent} from '../components/breadcrumbs/crumb-page.component';
import {FormComponent} from '../components/form/form.component';
import {EmailTemplateComponent} from 'app/components/email-template/email-template.component';
import {
  ProductEmailTemplatesComponent
} from 'app/pages/shop/product/detail/email-templates/product-email-templates.component';
import {PEditorComponent} from 'app/components/p-editor/p-editor.component';
import {EditorModule} from 'primeng/editor';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {MatIconModule} from '@angular/material/icon';
import {ArchivedSpinnerComponent} from '../components/archived-spinner/archived-spinner.component';
import {ArchivedModalComponent} from '../components/archived-modal/archived-modal.component';
import {DisabledModalComponent} from '../components/disabled-modal/disabled-modal.component';
import {MatTooltipModule} from '@angular/material/tooltip';

// tslint:enable:max-line-length

@NgModule({
  imports: [
    HttpClientModule,
    CurrencyMaskModule,
    FormsModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    ModalModule,
    CollapseModule,
    BsDropdownModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbPopoverModule,
    DynamicModule,
    DynamicIoModule,
    // CustomFormsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgbPaginationModule,
    EditorModule,
    MatSnackBarModule,
    MatIconModule
  ],
  providers: [
    CustomSnackbarService
  ],
  declarations: [
    ReportBoxComponent,
    BoxComponent,
    TimelineComponent,
    ConfirmModalComponent,
    ArchivedModalComponent,
    DisabledModalComponent,
    ConfirmSendModalComponent,
    ConfirmUnmergeModalComponent,
    ListComponent,
    StrippedListComponent,
    ListHeaderComponent,
    BreadcrumbsComponent,
    InputFieldComponent,
    RadioButtonComponent,
    QuestionMarkComponent,
    SecureActionDirective,
    DisableDuringRequestDirective,
    ColumnTemplateComponent,
    LoginComponent,
    LoginContentComponent,
    NawFormComponent,
    CompanyFormComponent,
    ReferrerPageComponent,
    EmployerPageComponent,
    ClientPageComponent,
    NawCompanyFormComponent,
    IdentitySelectionComponent,
    ConfirmationPageComponent,
    SummaryPageComponent,
    QuotationProductComponent,
    ProductInfoPageComponent,
    ProductSessionsPageComponent,
    SessionListComponent,
    SessionPageComponent,
    SessionDetailComponent,
    IdentitiesFormComponent,
    CommunicationInfoComponent,
    CommunicationFormComponent,
    ExtraProductFieldsComponent,
    ContactButtonsComponent,
    FlowInfoComponent,
    InputDatePickerComponent,
    DatetimePickerComponent,
    InputDatetimePickerComponent,
    DatePickerComponent,
    CurrencyInputFieldComponent,
    TextAreaComponent,
    SafeHtmlPipe,
    IdentityNamePipe,
    NawNamePipe,
    LocaldateFormatPipe,
    LocaldatetimeFormatPipe,
    TryTranslatePipe,
    ActionTranslatePipe,
    WordBreakEmailPipe,
    RichTextModalComponent,
    NavbarMenuComponent,
    NavbarMenuSubComponent,
    IdentitySideMenuComponent,
    IdentitiesDropdownComponent,
    ModalDefaultComponent,
    RolePageComponent,
    FileUploadComponent,
    FilesUploadComponent,
    ApproveOrRejectQuotationComponent,
    ChangeEmailModalComponent,
    RejectReasonsComponent,
    RejectQuotationModalComponent,
    DropdownExtraComponent,
    SideMenuIdentityComponent,
    SortContentComponent,
    QuotationContactSummaryComponent,
    CancelQuotationModalComponent,
    StopServiceModalComponent,
    PauseServiceModalComponent,
    SkipModalComponent,
    SwapServiceModalComponent,
    DropdownComponent,
    CollapsibleComponent,
    ProductSessionComponent,
    ApproveOrRejectDataModalComponent,
    ApproveOrRejectDataFormComponent,
    ApproveOrRejectDataComponent,
    SpinnerComponent,
    ArchivedSpinnerComponent,
    SimpleDocumentActionRowComponent,
    DocumentActionRowComponent,
    MultipleChoiceComponent,
    TextAreaInputComponent,
    OpenFileComponent,
    CategoryProductDropdownComponent,
    SkipSessionsAndWeeksComponent,
    ShowPasswordEyeIconComponent,
    PaginationControlsComponent,
    PEditorComponent,
    CrumbPageComponent,
    FormComponent,
    ProductEmailTemplatesComponent,
    EmailTemplateComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ModalModule,
    CollapseModule,
    TranslateModule,
    BsDropdownModule,
    NgbPopoverModule,
    ReportBoxComponent,
    BoxComponent,
    TimelineComponent,
    ConfirmModalComponent,
    ArchivedModalComponent,
    DisabledModalComponent,
    ConfirmSendModalComponent,
    ConfirmUnmergeModalComponent,
    StrippedListComponent,
    ListComponent,
    ListHeaderComponent,
    BreadcrumbsComponent,
    InputFieldComponent,
    RadioButtonComponent,
    QuestionMarkComponent,
    ColumnTemplateComponent,
    LoginComponent,
    LoginContentComponent,
    IdentitiesFormComponent,
    NawFormComponent,
    CompanyFormComponent,
    CommunicationInfoComponent,
    CommunicationFormComponent,
    ExtraProductFieldsComponent,
    SecureActionDirective,
    DisableDuringRequestDirective,
    DatePickerComponent,
    InputDatePickerComponent,
    DatetimePickerComponent,
    InputDatetimePickerComponent,
    InputDatetimePickerComponent,
    CurrencyInputFieldComponent,
    FlowInfoComponent,
    DatePickerComponent,
    TextAreaComponent,
    FlowInfoComponent,
    SafeHtmlPipe,
    NgbPopoverModule,
    IdentityNamePipe,
    NawNamePipe,
    LocaldateFormatPipe,
    LocaldatetimeFormatPipe,
    TryTranslatePipe,
    WordBreakEmailPipe,
    RichTextModalComponent,
    NavbarMenuComponent,
    NavbarMenuSubComponent,
    IdentitySideMenuComponent,
    IdentitiesDropdownComponent,
    ModalDefaultComponent,
    RolePageComponent,
    FileUploadComponent,
    FilesUploadComponent,
    ChangeEmailModalComponent,
    RejectReasonsComponent,
    PEditorComponent,
    SideMenuIdentityComponent,
    QuotationContactSummaryComponent,
    DropdownExtraComponent,
    CollapsibleComponent,
    ProductSessionComponent,
    DropdownComponent,
    SpinnerComponent,
    ArchivedSpinnerComponent,
    SimpleDocumentActionRowComponent,
    DocumentActionRowComponent,
    MultipleChoiceComponent,
    TextAreaInputComponent,
    OpenFileComponent,
    CategoryProductDropdownComponent,
    SkipSessionsAndWeeksComponent,
    ActionTranslatePipe,
    CrumbPageComponent,
    FormComponent,
    ProductEmailTemplatesComponent,
    EmailTemplateComponent,
    MatTooltipModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // allows custom html elements, such as <ion-icon/>
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
