import {Injectable} from '@angular/core';
import {SettingsService} from '../settings/settings.service';
import {HttpClient} from '@angular/common/http';
 import {combineLatest, firstValueFrom, Observable, of, zip} from 'rxjs';
import {map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {Identity, IdentityListTypes, Naw} from '../../domain/identity/identitydata';
import {ListColumn} from '../../domain/list/list-data';
import {IdentityTypes} from '../../domain/identity/identitytypes';
import {TranslateService} from '@ngx-translate/core';
import {IdentityWithMetadata} from '../../domain/identity/identitywithmetadata';
import {ListSettings, SortDirection} from '../../components/list/list-settings';
import {IAssetWithData} from '../../domain/assets';
import {checkFileUploadError, getFormData} from '../formHelper';
import {User} from '../../domain/userdata';
import {IdentityTableData, IIdentityColumnData} from '../../domain/identity/identityTableData';
import {getFilterOptionsOperator} from '../../components/list/list-functions';
import {getAutoFilterItems$, getCount$} from '../paginationHelper';
import {serviceStatusFilters} from 'app/domain/service/service-status';
import {ModalService} from '../modal/modal.service';
import {DbId} from '../../domain/dbid';
import {LoginStateService} from '../login/loginstate.service';

/**
 * Service implementation for Identities.
 */
@Injectable({
  providedIn: 'root',
})
export class IdentityService {

  constructor(
    private httpService: HttpClient,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private loginStateService: LoginStateService
  ) {
  }

  getById$(id: number): Observable<Identity> {
    return this.httpService.get(this.settingsService.backendUrl(`/identity/${id}`))
      .pipe(map((result) => JSON.deserialize<Identity>(result, Identity)));
  }

  getByIdWithMetadata$(id: number, serviceId?: number): Observable<IdentityWithMetadata> {
    const route = serviceId ? `/identity/${id}/withMetadata/${serviceId}` : `/identity/${id}/withMetadata`;
    return this.httpService.get(this.settingsService.backendUrl(route))
      .pipe(map((result) => JSON.deserialize<IdentityWithMetadata>(result, IdentityWithMetadata)));
  }

  getActiveByType$(idType: IdentityTypes): Observable<IdentityWithMetadata[]> {
    return this.httpService.get(this.settingsService.backendUrl(
      `/identities/active/${idType}`
    )).pipe(map((result) => JSON.deserialize<IdentityWithMetadata[]>(result, IdentityWithMetadata)));
  }

  getActiveAndValidByType$(idType: IdentityTypes): Observable<IdentityWithMetadata[]> {
    return this.httpService.get(this.settingsService.backendUrl(
      `/identities/active/valid/${idType}`
    )).pipe(map((result) => JSON.deserialize<IdentityWithMetadata[]>(result, IdentityWithMetadata)));
  }

  getAllByRelation$(idType: IdentityTypes,
                    checkChildrenIdentities: boolean,
                    referrerId: number,
                    companyId: number): Observable<IdentityWithMetadata[]> {

    const queryParameter = !!referrerId && companyId ? `?referrerId=${referrerId}&companyId=${companyId}` :
      referrerId ? `?referrerId=${referrerId}` :
        companyId ? `?companyId=${companyId}` :
          '';
    return this.httpService.get(this.settingsService.backendUrl(
      `/identities/allOfRelation/${idType}/${checkChildrenIdentities}${queryParameter}`
    )).pipe(map((result) =>
      JSON.deserialize<IdentityWithMetadata[]>(result, IdentityWithMetadata)
    ));
  }

  getOwnParentsByType$(idType: IdentityTypes): Observable<IdentityWithMetadata[]> {
    return this.httpService.get(this.settingsService.backendUrl(`/identities/ownparents/${idType}`))
      .pipe(map((result) => JSON.deserialize<IdentityWithMetadata[]>(result, IdentityWithMetadata)));
  }

  getCoachesOf$(id: number): Observable<Identity[]> {
    return this.httpService.get(this.settingsService.backendUrl(`/identity/${id}/coaches`))
      .pipe(map((result) => JSON.deserialize<Identity[]>(result, Identity)));
  }

  mergeIdentities(idType: IdentityTypes, mainId: number, siblingId: number): Observable<boolean> {
    return this.httpService.post(
      this.settingsService.backendUrl(`/identities/${idType}/${mainId}/merge/${siblingId}`), {})
      .pipe(map((result) => JSON.deserialize<boolean>(result, Boolean)));
  }

  update$(identity: Identity): Observable<boolean> {
    return this.httpService.put(
      this.settingsService.backendUrl(`/identity`),
      JSON.serialize(identity),
      {observe: 'response'}
    ).pipe(map((result) => result.status === 200));
  }

  updateAdditionalInfo$(updatedInfo: string, id: DbId) {
    return this.httpService.put(
      this.settingsService.backendUrl(`/identity/bijzonderheden`),
      JSON.serialize({updatedInfo, id: id.id}),
      {observe: 'response'}
    ).pipe(map((result) => result.status === 200));
  }

  updateWithAssets$(user: User, picture: IAssetWithData, profile: IAssetWithData): Observable<boolean> {
    return this.httpService.put(
      this.settingsService.backendUrl(`/identity/withAssets`),
      getFormData(user, [picture, profile]),
      {observe: 'response'}
    ).pipe(
      checkFileUploadError(this.modalService, user.isInternalUser()),
      map((result) => result.status === 200)
    );
  }

  resetFirstLogin(user: User): Observable<boolean> {
    return this.httpService.post(
      this.settingsService.backendUrl(`/identity/resetFirstLogin`),
      JSON.serialize(user),
      {observe: 'response'}
    ).pipe(
      map((res) => res.status === 200)
    );
  }

  createNaw$(naw: Naw): Observable<[boolean, number]> {
    return this.httpService.post(
      this.settingsService.backendUrl(`/identity/createNaw`),
      JSON.serialize(naw),
      {observe: 'response'}
    ).pipe(map((result) => [result.status === 200, JSON.deserialize<number>(result.body)]));
  }

  /**
   * Methods for getting table data
   * Optionally sends along the id of an identity to exclude from the list, used for merge table
   */
  getTableDataMainByType$(idType: IdentityTypes, listSettings: ListSettings, idToExclude: number): Observable<IdentityTableData[]> {
    const limit: number = listSettings.paginationParams.limit;
    const offset: number = listSettings.paginationParams.offset;
    return this.httpService.post(this.settingsService.backendUrl(
      `/identities/main/tableData/${idType}${idToExclude ? '/' + idToExclude : ''}?limit=${limit}&offset=${offset}`
    ), JSON.serialize(listSettings)).pipe(map((result) => JSON.deserialize<IdentityTableData[]>(result, IdentityTableData)));
  }

  getTableDataMainOpdrachtgeverByType$(idType: IdentityTypes, listSettings: ListSettings): Observable<IdentityTableData[]> {
    const limit: number = listSettings.paginationParams.limit;
    const offset: number = listSettings.paginationParams.offset;
    return this.httpService.post(this.settingsService.backendUrl(
      `/identities/opdrachtgever/main/tableData/${idType}?limit=${limit}&offset=${offset}`
    ), JSON.serialize(listSettings)).pipe(map((result) => JSON.deserialize<IdentityTableData[]>(result, IdentityTableData)));
  }

  getTableDataAllByRelation$(idType: IdentityTypes,
                             relationType: IdentityTypes,
                             relationId: number,
                             checkChildrenIdentities: boolean,
                             listSettings: ListSettings): Observable<IdentityTableData[]> {
    const limit: number = listSettings.paginationParams.limit;
    const offset: number = listSettings.paginationParams.offset;
    return this.httpService.post(this.settingsService.backendUrl(
      /* eslint-disable max-len */
      `/identities/allOfRelation/tableData/${idType}/${relationType}/${relationId}/${checkChildrenIdentities}?limit=${limit}&offset=${offset}`
      /* eslint-disable max-len */
    ), JSON.serialize(listSettings)).pipe(map((result) => JSON.deserialize<IdentityTableData[]>(result, IdentityTableData)));
  }

  getTableDataSiblings$(idType: IdentityTypes, parentId: number, listSettings: ListSettings): Observable<IdentityTableData[]> {
    const limit: number = listSettings.paginationParams.limit;
    const offset: number = listSettings.paginationParams.offset;
    return this.httpService.post(this.settingsService.backendUrl(
        `/identities/tableData/${idType}/${parentId}?limit=${limit}&offset=${offset}`),
      JSON.serialize(listSettings))
      .pipe(map((result) => JSON.deserialize<IdentityTableData[]>(result, IdentityTableData)));
  }

  getTableDataAllByType$(idType: IdentityTypes, listSettings: ListSettings): Observable<IdentityTableData[]> {
    const limit: number = listSettings.paginationParams.limit;
    const offset: number = listSettings.paginationParams.offset;
    return this.httpService.post(this.settingsService.backendUrl(
        `/identities/tableData/${idType}?limit=${limit}&offset=${offset}`),
      JSON.serialize(listSettings))
      .pipe(map((result) => JSON.deserialize<IdentityTableData[]>(result, IdentityTableData)));
  }

  getTableDataOpdrachtgeverByType$(idType: IdentityTypes, listSettings: ListSettings): Observable<IdentityTableData[]> {
    const limit: number = listSettings.paginationParams.limit;
    const offset: number = listSettings.paginationParams.offset;
    return this.httpService.post(
      this.settingsService.backendUrl(`/identities/opdrachtgever/tableData/${idType}?limit=${limit}&offset=${offset}`),
      JSON.serialize(listSettings)
    ).pipe(map((result) => JSON.deserialize<IdentityTableData[]>(result, IdentityTableData)));
  }

  getTableDataMainByTypeCount$(idType: IdentityTypes, listSettings: ListSettings, idToExclude: number): Observable<number> {
    return getCount$(listSettings, `/identities/main/tableData/${idType}${idToExclude ? '/' + idToExclude : ''}`,
      this.httpService, this.settingsService);
  }

  getTableDataMainOpdrachtgeverByTypeCount$(idType: IdentityTypes, listSettings: ListSettings): Observable<number> {
    return getCount$(listSettings, `/identities/opdrachtgever/main/tableData/${idType}`, this.httpService, this.settingsService);
  }

  getTableDataAllByRelationCount$(idType: IdentityTypes,
                                  relationType: IdentityTypes,
                                  relationId: number,
                                  checkChildrenIdentities: boolean, listSettings: ListSettings
  ): Observable<number> {
    return getCount$(listSettings,
      `/identities/allOfRelation/tableData/${idType}/${relationType}/${relationId}/${checkChildrenIdentities}`,
      this.httpService, this.settingsService);
  }

  getTableDataSiblingsCount$(idType: IdentityTypes, parentId: number, listSettings: ListSettings): Observable<number> {
    return getCount$(listSettings, `/identities/tableData/${idType}/${parentId}`, this.httpService, this.settingsService);
  }

  getTableDataAllByTypeCount$(idType: IdentityTypes, listSettings: ListSettings): Observable<number> {
    return getCount$(listSettings, `/identities/tableData/${idType}`, this.httpService, this.settingsService);
  }

  getTableDataOpdrachtgeverByTypeCount$(idType: IdentityTypes, listSettings: ListSettings): Observable<number> {
    return getCount$(listSettings, `/identities/opdrachtgever/tableData/${idType}`, this.httpService, this.settingsService);
  }

  getTableDataMainByTypeAutoFilter$(
    viewFieldId: string,
    sortFieldId: string,
    idType: IdentityTypes,
    idToExclude: number
  ): Observable<string[]> {
    return getAutoFilterItems$(`/identities/main/tableData/${idType}${idToExclude ? '/' + idToExclude : ''}`,
      viewFieldId, sortFieldId, this.httpService, this.settingsService);
  }

  getTableDataMainOpdrachtgeverByTypeAutoFilter$(viewFieldId: string, sortFieldId: string, idType: IdentityTypes): Observable<string[]> {
    return getAutoFilterItems$(`/identities/opdrachtgever/main/tableData/${idType}`, viewFieldId, sortFieldId,
      this.httpService, this.settingsService);
  }

  getTableDataAllByRelationAutoFilter$(
    viewFieldId: string,
    sortFieldId: string,
    idType: IdentityTypes,
    relationType: IdentityTypes,
    relationId: number,
    checkChildrenIdentities: boolean
  ): Observable<string[]> {
    return getAutoFilterItems$(`/identities/allOfRelation/tableData/${idType}/${relationType}/${relationId}/${checkChildrenIdentities}`,
      viewFieldId, sortFieldId, this.httpService, this.settingsService);
  }

  getTableDataSiblingsAutoFilter$(viewFieldId: string, sortFieldId: string, idType: IdentityTypes, parentId: number): Observable<string[]> {
    return getAutoFilterItems$(`/identities/tableData/${idType}/${parentId}`, viewFieldId, sortFieldId,
      this.httpService, this.settingsService);
  }

  getTableDataAllByTypeAutoFilter$(viewFieldId: string, sortFieldId: string, idType: IdentityTypes): Observable<string[]> {
    return getAutoFilterItems$(`/identities/tableData/${idType}`, viewFieldId, sortFieldId,
      this.httpService, this.settingsService);
  }

  getTableDataOpdrachtgeverByTypeAutoFilter$(viewFieldId: string, sortFieldId: string, idType: IdentityTypes): Observable<string[]> {
    return getAutoFilterItems$(`/identities/opdrachtgever/tableData/${idType}`, viewFieldId, sortFieldId,
      this.httpService, this.settingsService);
  }

  /**
   * Helper functions for identity lists
   */

  /**
   * Sets columns and column order per identitytype
   */
  getColumnsForType$(
    idType: IdentityTypes,
    listType: IdentityListTypes,
    isInternalUser: boolean,
    parentId: number = null,
    currentIdentityType: IdentityTypes = null,
    currentIdentityId: number = null
  ): Observable<ListColumn<IIdentityColumnData>[]> {
    const nameCol = new ListColumn<IIdentityColumnData>('fullName', 'string', null, 'identity.sortValue', null,
      SortDirection.Unsorted, [], false, false, null, null, null, null, 'identity.fullName_alternate');
    const emailCol = new ListColumn<IIdentityColumnData>('email', 'string', null, 'identity.email');
    const phoneCol = new ListColumn<IIdentityColumnData>('phoneNumber', 'string', null, 'identity.phoneNumber');
    const funcCol = new ListColumn<IIdentityColumnData>('jobFunction', 'string', null, 'identity.jobFunction');
    const companyCol = new ListColumn<IIdentityColumnData>('company', 'string', null, 'identity.mainCompany',
      null, SortDirection.Unsorted, [],
      false, false, null, null, null, null, 'identity.mainCompany');
    const cityCol = new ListColumn<IIdentityColumnData>('city', 'string', null, 'identity.mainCity');
    const productCol = new ListColumn<IIdentityColumnData>('serviceProduct', 'string', null, 'identity.serviceProduct');
    const statusCol = new ListColumn<IIdentityColumnData>('serviceStatus', 'string', null, 'identity.serviceStatusSortValue',
      'identity.serviceStatus', SortDirection.Unsorted, serviceStatusFilters(isInternalUser));
    const coachCol$: Observable<ListColumn<IIdentityColumnData>> =
      this.getAllFilterItemsForIdentityList(idType, listType, 'identity.coachFullname', 'identity.coachSortValue',
        parentId, currentIdentityType, currentIdentityId).pipe(
        getFilterOptionsOperator,
        map((filterItems) =>
          new ListColumn<IIdentityColumnData>('serviceCoach', 'string', null, 'identity.coachSortValue', 'identity.coachFullname',
            SortDirection.Unsorted, filterItems)
        )
      );

    const archiveCol = Object.assign(new ListColumn('archiveButton', ''), {allowSorting: false});
    const ownerCol = new ListColumn<IIdentityColumnData>('serviceOwner', 'string', null, 'identity.ownerSortValue');
    const trajectAssistentCol$: Observable<ListColumn<IIdentityColumnData>> =
      this.getAllFilterItemsForIdentityList(idType, listType, 'identity.trajectAssistentFullname',
        'identity.trajectAssistentSortValue', parentId, currentIdentityType, currentIdentityId).pipe(
        getFilterOptionsOperator,
        map((filterItems) =>
          new ListColumn<IIdentityColumnData>('serviceTrajectAssistent', 'string', null,
            'identity.trajectAssistentSortValue', 'identity.trajectAssistentFullname', SortDirection.Unsorted, filterItems, false, true)
        )
      );

    switch (listType) {
      case IdentityListTypes.SiblingList:
        switch (idType) {
          case IdentityTypes.Client:
            return of([nameCol, emailCol, phoneCol, funcCol, cityCol]);
          case IdentityTypes.Doorverwijzer:
            return of([nameCol, emailCol, phoneCol, cityCol, companyCol]);
          default:
            return of([nameCol, emailCol, phoneCol, funcCol, cityCol, companyCol]);
        }
      case IdentityListTypes.OpdrachtgeverList:
      case IdentityListTypes.OpdrachtgeverListAll:
        switch (idType) {
          case IdentityTypes.Werkgever:
          case IdentityTypes.Doorverwijzer:
            return of([companyCol, cityCol, nameCol]);
          default:
            return of([nameCol, cityCol]);
        }
      case IdentityListTypes.InternList:
      case IdentityListTypes.InternListAll:
        switch (idType) {
          case IdentityTypes.Client:
            return coachCol$.pipe(
              map((coachCol) =>
                [nameCol, cityCol, productCol, statusCol, coachCol]
              )
            );
          case IdentityTypes.Werkgever:
          case IdentityTypes.Doorverwijzer:
            return of([companyCol, cityCol, nameCol]);
          default:
            return of([nameCol, companyCol, cityCol]);
        }
      case IdentityListTypes.MainList:
      case IdentityListTypes.MainListAll:
        switch (idType) {
          case IdentityTypes.Client:
            if (isInternalUser) {
              return combineLatest([coachCol$, trajectAssistentCol$]).pipe(
                map(([coachCol, trajectAssistentCol]) => {
                    const defList = [nameCol, cityCol, ownerCol, productCol, statusCol, coachCol, trajectAssistentCol];
                    return this.loginStateService.hasArchivingPermission() ? [...defList, archiveCol] : defList;
                  }
                )
              );
            } else {
              return coachCol$.pipe(
                map((coachCol) =>
                  [nameCol, companyCol, productCol, statusCol, coachCol]
                )
              );
            }
          case IdentityTypes.Werkgever:
          case IdentityTypes.Doorverwijzer:
            return of([companyCol, cityCol, nameCol]);
          default:
            return of([nameCol, companyCol, cityCol]);
        }
    }
  }

  getAllForIdentityList$(
    idType: IdentityTypes,
    listType: IdentityListTypes,
    listSettings: ListSettings,
    parentId: number = null,
    currentIdentityType: IdentityTypes = null,
    currentIdentityId: number = null
  ): Observable<[IdentityTableData[], number]> {
    let identities$: Observable<IdentityTableData[]>;
    let count$: Observable<number>;

    switch (listType) {
      case IdentityListTypes.MainList:
        identities$ = this.getTableDataMainByType$(idType, listSettings, currentIdentityId);
        count$ = this.getTableDataMainByTypeCount$(idType, listSettings, currentIdentityId);
        break;
      case IdentityListTypes.SiblingList:
        identities$ = this.getTableDataSiblings$(idType, parentId, listSettings);
        count$ = this.getTableDataSiblingsCount$(idType, parentId, listSettings);
        break;
      case IdentityListTypes.InternList:
        identities$ = this.getTableDataAllByRelation$(idType, currentIdentityType, currentIdentityId, false, listSettings);
        count$ = this.getTableDataAllByRelationCount$(idType, currentIdentityType, currentIdentityId, false, listSettings);
        break;
      case IdentityListTypes.InternListAll:
        identities$ = this.getTableDataAllByRelation$(idType, currentIdentityType, currentIdentityId, true, listSettings);
        count$ = this.getTableDataAllByRelationCount$(idType, currentIdentityType, currentIdentityId, true, listSettings);
        break;
      case IdentityListTypes.MainListAll:
        identities$ = this.getTableDataAllByType$(idType, listSettings);
        count$ = this.getTableDataAllByTypeCount$(idType, listSettings);
        break;
      case IdentityListTypes.OpdrachtgeverList:
        identities$ = this.getTableDataMainOpdrachtgeverByType$(idType, listSettings);
        count$ = this.getTableDataMainOpdrachtgeverByTypeCount$(idType, listSettings);
        break;
      case IdentityListTypes.OpdrachtgeverListAll:
        identities$ = this.getTableDataOpdrachtgeverByType$(idType, listSettings);
        count$ = this.getTableDataOpdrachtgeverByTypeCount$(idType, listSettings);
        break;
      default: // nothing
    }

    return zip(identities$, count$);
  }

  getAllFilterItemsForIdentityList(
    idType: IdentityTypes,
    listType: IdentityListTypes,
    viewFieldId: string,
    sortFieldId: string,
    parentId: number = null,
    currentIdentityType: IdentityTypes = null,
    currentIdentityId: number = null
  ): Observable<string[]> {
    let filterItems$: Observable<string[]>;

    switch (listType) {
      case IdentityListTypes.MainList:
        filterItems$ = this.getTableDataMainByTypeAutoFilter$(viewFieldId, sortFieldId, idType, currentIdentityId);
        break;
      case IdentityListTypes.SiblingList:
        filterItems$ = this.getTableDataSiblingsAutoFilter$(viewFieldId, sortFieldId, idType, parentId);
        break;
      case IdentityListTypes.InternList:
        filterItems$ = this.getTableDataAllByRelationAutoFilter$(viewFieldId, sortFieldId, idType,
          currentIdentityType, currentIdentityId, false);
        break;
      case IdentityListTypes.InternListAll:
        filterItems$ = this.getTableDataAllByRelationAutoFilter$(viewFieldId, sortFieldId, idType,
          currentIdentityType, currentIdentityId, true);
        break;
      case IdentityListTypes.MainListAll:
        filterItems$ = this.getTableDataAllByTypeAutoFilter$(viewFieldId, sortFieldId, idType);
        break;
      case IdentityListTypes.OpdrachtgeverList:
        filterItems$ = this.getTableDataMainOpdrachtgeverByTypeAutoFilter$(viewFieldId, sortFieldId, idType);
        break;
      case IdentityListTypes.OpdrachtgeverListAll:
        filterItems$ = this.getTableDataOpdrachtgeverByTypeAutoFilter$(viewFieldId, sortFieldId, idType);
        break;
      default: // nothing
    }

    return filterItems$;
  }

  identityHasOngoingServices(id: DbId): Observable<boolean> {
    return this.httpService.get(
      this.settingsService.backendUrl('/identity/hasOngoingServices/' + id.id)
    ).pipe(map((result) => JSON.deserialize<boolean>(result, Boolean)));
  }

  identityToIdentityListColumnData(identities: IdentityTableData[]): IIdentityColumnData[] {
    return identities
      .map((identity) => this.setIdentityColumnData(identity))
      .filter((colData) => colData !== undefined);
  }

  unmerge(id: number, idType: IdentityTypes): Observable<boolean> {
    return this.httpService.put(this.settingsService.backendUrl(`/identities/${idType}/unmerge/${id}`), {})
      .pipe(map((result) => JSON.deserialize<boolean>(result, Boolean)));
  }

  unarchiveIdentity(id: DbId): Promise<void> {
    // we only need to know if the promise succeeds or fails, so just map the result to `null`.
    return firstValueFrom(this.httpService.put(this.settingsService.backendUrl('/identity/unArchive/' + id.id), {}).pipe(map(_ => null)));
  }

  // Note: toggleNew and archiveButton are added in the list component after the "regular" columns are added
  private setIdentityColumnData(identityTableData: IdentityTableData): IIdentityColumnData {
    const identityDetails = identityTableData.identity;
    return {
      identity: identityDetails,
      fullName: identityDetails.fullNames.alternate,
      lastName: identityDetails.naw ? identityDetails.naw.lastName : '',
      email: identityDetails.naw ? identityDetails.naw.email : '',
      phoneNumber: identityDetails.naw ? identityDetails.naw.phoneNumber : '',
      jobFunction: identityDetails.naw ? identityDetails.naw.jobTitle : '',
      company: identityTableData.mainCompanyName ? identityTableData.mainCompanyName : '',
      city: identityDetails.mainCity ? identityDetails.mainCity : '',
      serviceProduct: identityTableData.serviceName,
      serviceStatus: identityTableData.serviceStatus ?
        this.translateService.instant(`quotationOrActionCodes.${identityTableData.serviceStatus.detailedStatus}`) : null,
      serviceCoach: identityTableData.coach?.fullNames.normal,
      coachLastName: !!identityTableData.coach && !!identityTableData.coach.naw ?
        identityTableData.coach.naw.lastName : '',
      createdAt: identityDetails.createdAt,
      serviceOwner: identityTableData.serviceOwner?.fullNames.companyAlternate,
      ownerId: identityTableData.identity.ownerId,
      serviceTrajectAssistent: identityTableData.serviceTrajectAssistent?.fullNames.normal,
      isOwner: identityDetails.id.id === identityDetails.ownerId,
    };
  }
}
