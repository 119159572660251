import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ApplicationRef, DoBootstrap, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from 'app/app.component';
import {AppRoutingModule} from 'app/app.routing';
import {httpInterceptorProviders} from './services/http/index';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SharedModule} from 'app/shared/shared.module';
import {LayoutModule} from 'app/layouts/layout.module';
import {SentryErrorHandler} from './errors/sentry';
import {registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {EnvironmentConfig, loadEnvironmentFromFile} from './configs/environment.config';
import {EnvironmentService} from './services/environment/environment.service';
import {BackendTranslationLoader} from './services/translator/backend-translation-loader';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {ModalModule} from "ngx-bootstrap/modal";

registerLocaleData(localeNl, 'nl');

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpBackend, env: EnvironmentService) => new BackendTranslationLoader(http, env),
        deps: [HttpBackend, EnvironmentService]
      }
    }),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    LayoutModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      // There is some very black (as in: poorly documented) magic happening here, so I'll elaborate:
      // (TLDR at the bottom)
      //
      // 1. APP_INITIALIZER, unlike regular providers, expects a FUNCTION as a 'value'; when we want to use a factory,
      // it therefore expects a function that has as return type that is a FUNCTION.
      // 2. Then, IF the function that is resolved as the 'value' for APP_INITIALIZER returns an 'async' object
      // (i.e. observable or promise), the RESULT OF THE FUNCTION is actually awaited before we create anything else.
      // 3. If you provide just an observable/promise here, that will NOT have the desired (or expected) effect, and
      // providing a direct function has some issues with properly providing the dependencies.
      // 4. The below factory works because the loadEnvironmentFromFile only returns a value once the environmentSvc
      // actually returns a config (which also means the sync value is safe to get) -> check the function for details.
      //
      // Some further notes, for how the application is actually instantiated (this is general Angular stuff):
      // 1. Any APP_INITIALIZER providers are instantiated -> before this can happen, the deps of these providers need
      // to be readily present and NOT dependent on anything that requires an APP_INITIALIZER to resolve!!
      // 2. The initialization is pretty dumb. So it will basically start all APP_INITIALIZER providers at the same
      // time. Then, once those have finished resolving, it will resolve all other providers. After that, it will
      // do its best to spin up the AppComponent by calling its constructor, injecting (and spinning up) any of its
      // dependencies as needed.
      //
      //
      // WHAT DOES THAT MEAN FOR ME? !IMPORTANT!
      // 1. Be careful with adding more APP_INITIALIZER providers. These should NEVER depend on anything that in turn
      // depends on the Environment config.
      // 2. For regular constructors, the EnvironmentService should generally not be used or necessary.
      // Instead, you can simply directly inject an instance of EnvironmentConfig!
      // 3. Need a service to be instantiated as an (effectively) eager singleton? Provide it as an argument of the
      // constructor in the AppComponent (e.g. currently UserService, PermissionService).
      provide: APP_INITIALIZER,
      useFactory: (httpClient: HttpClient, env: EnvironmentService) =>
        loadEnvironmentFromFile(httpClient, env),
      deps: [HttpClient, EnvironmentService],
      multi: true
    },
    httpInterceptorProviders,
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {
      provide: EnvironmentConfig,
      useFactory: (env: EnvironmentService) => env.getEnv(),
      deps: [EnvironmentService]
    },
  ]
})
export class AppModule implements DoBootstrap {
  private app: ApplicationRef;

  /**
   * Use the bootstrap hook to perform the chcp update
   *
   * @param app
   */
  ngDoBootstrap(app: ApplicationRef): void {
    this.app = app;
    let counter: number = 0;
    const cycletime: number = 200; // ms
    const safetytime: number = 10; // min

    // Poll the chcpFinished flag to check if angular can be started
    const timer = setInterval(() => {
      const chcpFinished = window['chcpFinishedFlag'];

      if (chcpFinished === undefined || chcpFinished || counter > (safetytime * 60 * 1000 / cycletime)) {
        this.startAngular();
        clearInterval(timer);
      }

      counter++;
    }, cycletime);
  }

  /**
   * Bootstrap Angular to make it start
   */
  private startAngular(): void {
    this.app.bootstrap(AppComponent);
  }
}
